<template>
  <vx-card title="Import Excel">
    
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          class="inputx"
          type="file"
          v-if="uploadReady"
          ref="file"
          name="file"
          accept=".xls, .xlsx"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="ml-auto mt-2" v-on:click="handleSubmit">Submit</vs-button>
        <!-- <vs-button
          class="ml-4 mt-2"
          type="border"
          color="warning"
          v-on:click="handleDownload"
        >Download Template</vs-button> -->
      </div>
    </div>
    <hr>
    <vs-tabs >
        <vs-tab  label="Done">
          <div class="con-tab-ejemplo">
            <approve :reload="reload"></approve>
          </div>
        </vs-tab>
        <vs-tab  label="Pending">
          <div class="con-tab-ejemplo">
            <pending :reload="reload"></pending>
            
          </div>
        </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import approve from "./approve/approve.vue";
import pending from "./pending/pending.vue";
export default {
  components:{
    'approve' : approve,
    'pending' : pending
  },
  data() {
    return {
      territoryData: [],
      territorySelected: null,
      uploadReady: true,
      salesChannel: {
        options: [],
        selected: null
      },
      reload:true,
      salesChannelId: null
    };
  },
  methods: {
    handleDownload() {
      
        this.$vs.loading();
        this.$http
          .get("/api/v1/invoice/reconcile", {
            params: {
              territory_code:1
            },
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream"
            }
          })
          .then(resp => {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "invoice-template.xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
          });
    },
    handleSubmit() {
      var file = this.$refs.file.files[0];
      if (file == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
      } else {
        this.formData = new FormData();
        this.formData.append("file", file);
        this.$http
          // .post("/api/v1/invoice/reconcile", this.formData, {
          .post("/api/v1/payment/reconcile", this.formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(resp => {
            if (resp.status == "success") {
              var message = "";
              // resp.data.map(function(item, index) {
              //   var total = resp.data.length;
              //   if (index == total - 1) {
              //     message += item.sales_order_code;
              //   } else {
              //     message += item.sales_order_code + ", ";
              //   }
              // });
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              this.uploadReady = false;
              this.$nextTick(() => {
                this.reload = !this.reload
                this.uploadReady = true;
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: error,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          });
      }
    },
    getAllDataSalesChannel() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/sales-channel", {
          params: {
            order: "name",
            sort: "asc"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.salesChannel.options = resp.data.records;
            if (this.salesChannel.options.length > 0) {
              this.salesChannel.selected = this.salesChannel.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    }
  },
  mounted() {
    // this.getTerritoryData();
    // this.getAllDataSalesChannel();
  },
  watch: {
    "salesChannel.selected": function(v) {
      this.salesChannelId = v.id;
    }
  }
};
</script>
