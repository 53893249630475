<template>
  <div>
    <div class="vx-row margin-btm" style="width:100%">
      <div class="vx-col sm:w-3/3 w-full">
        <span>{{selected.Code}}</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">{{selected.CustomerName}}</div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
      @selectedDataFromChild="selectedRow"
    >
      <template slot="tbody">
        <vs-tr
          :data="tr"
          @click="selectedRow(tr)"
          :key="indextr"
          v-for="(tr, indextr) in data"
          v-bind:class="[selectedData.ID==tr.ID ? 'colored-detail' : '']"
        >
          <vs-td>{{tr.BankName}} - ({{tr.BankAccountNumber}} / {{tr.BankAccountOwner}})</vs-td>
          <vs-td>{{tr.PaymentCode}}</vs-td>
          <vs-td>{{tr.PaymentMethod}}</vs-td>
          <vs-td>{{priceFormat(tr.PaymentAmount)}}</vs-td>
          <vs-td>{{priceFormat(tr.PaymentCharge)}}</vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    dataId: Number,
    selected: {},
    header:{},
  },
  // created() {
  // },
  // mounted() {
  // },

  data: () => ({
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "asc",
      sort: "reference_code"
    },
    selectedData: {},
    header: [
      {
        text: "Bank",
        sortable: false
        // width: '5%'
      },
      {
        text: "Code",
        sortable: false
        // width: '5%'
      },
      {
        text: "Method",
        sortable: false
      },
      {
        text: "Amount",
        sortable: false
      },
      {
        text: "Charge",
        sortable: false
      }
    ],
    responseData: {}
  }),
  methods: {
    selectedRow(tr) {
      console.log(tr);
      console.log("tr");
      this.selectedData = tr;
      this.openConfirm()
    },
    openConfirm() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text:
          "Payment and Reconcile Has Been Approve ?",
        accept: this.acceptAlert
      });
    },
    acceptAlert() {
      let params = {
        reconcile_payment_id: this.selected.id,
        invoice_payment_id: this.selectedData.id
      };
      this.$vs.loading();
      this.$http
        .post("api/v1/invoice/reconcile/payment", JSON.stringify(params))
          .then(resp => {
            console.log(resp)
            this.$vs.notify({
              color: "success",
              title: "Deleted image",
              position:"top-right",
              text: ""
            });
            this.$emit("reloadDataFromChild",this.header)
          }
        )
    },
    reloadData(params) {
      console.log("sip");
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/payment/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            Type: 99,
          }
        })
        .then(resp => {
          if (resp.code == 200) {
            // this.responseData = resp.data.invoicePayments
            this.data = resp.data.invoicePayments;
            this.responseData = resp.data;
            console.log(this.responseData);
          }
          this.$vs.loading.close();
        });
    }
  }
};
</script>
<style scoped>
.colored-detail {
  border: 1px solid #7367f0;
  position: fixed;
  left: 50%;
  top: 60%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>